/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * Handles the click event on country list items (country-selector.hbs)
 * and country links in the world map submenu (header-language-country-selector.hbs).
 */
const clickOnCountry = () => {
  $('.ga4-country-link').on('click', event => {
    const $eventTarget = $(event.currentTarget);

    pushToDataLayer({
      event: 'ga4_geo_selector',
      ga4_data: {
        website_country: $eventTarget.data('country-code'),
        website_language: $eventTarget.data('language-code'),
      }
    });
  });
}

export const addGeoLocationsListeners = () => {
  clickOnCountry();
};
