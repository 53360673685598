/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

let formInteraction = false;

/**
 * Handles the interaction with the review form.
 *
 * @param {String} $productId - The ID of the product.
 * @param {String} $productName - The name of the product.
 */
const reviewFormInteractionListener = ($productId, $productName) => {
  $(document).on('input', '.bv-text, .pr-textinput, #0_title, #0_usernickname, #0_authenticationemail', () => {
    if (!formInteraction) {
      pushToDataLayer({
        event: 'ga4_review',
        ga4_data: {
          action_type: 'Start to write a review',
          single_item_id: $productId,
          single_item_name: $productName
        }
      });

      formInteraction = true;
    }
  });
}

/**
 * Handles the submission of a review form.
 *
 * @param {String} $productId - The ID of the product being reviewed.
 * @param {String} $productName - The name of the product being reviewed.
 */
const reviewFormSubmissionListener = ($productId, $productName) => {
  $(document).on('click', '.bv-submission-button-submit, .pr-btn-review, #bv-ips-submit', () => {
    pushToDataLayer({
      event: 'ga4_review',
      ga4_data: {
        action_type: 'Submit a review',
        single_item_id: $productId,
        single_item_name: $productName
      }
    });
  });
}

/**
 * Handles the click event for the "Write a review" CTA.
 * Call reviewFormInteractionListener and reviewFormSubmissionListener
 * functions to handle the form interaction and submission listeners after review button click.
 */
const writeReviewCtaClick = () => {
  const $productId = $('.js-product-intro-item').data('product-id');
  const $productName = $('.js-product-intro-item').data('product-name');

  $(document).on('click', '.bv-submission-button, .pr-snippet-write-review-link, .bv-reviews-product-rating-star', () => {
    pushToDataLayer({
      event: 'ga4_review',
      ga4_data: {
        action_type: 'Write a review cta click',
        single_item_id: $productId,
        single_item_name: $productName
      }
    });

    reviewFormInteractionListener($productId, $productName);
    reviewFormSubmissionListener($productId, $productName);
  });
}

export const addProductReviewsEvents = () => {
  writeReviewCtaClick();
};
